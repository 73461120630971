import * as React from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { ListItemIcon, Typography } from '@mui/material';
import { ConfirmationDialog } from '../common/ConfirmationDialog';
import { selectUserProfile } from '../../features/user/selectUserProfile';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { I18nFragment } from '../common/I18nFragment';
import { I18nTypes } from '../../App/types';
import { useTranslation } from '../../features/i18n/useTranslation';
import { deleteCurrentUserAccountThunk } from '../../features/user/userSlice';

interface ConfirmDeleteAccountDialogProps {
  open: boolean;

  handleCancel(): void;
}

const CONSEQUENCE_IDS: I18nTypes[] = [
  'SH.ACCNTDLTCNFRM.CONSEQUENCE1',
  'SH.ACCNTDLTCNFRM.CONSEQUENCE2',
  'SH.ACCNTDLTCNFRM.CONSEQUENCE3',
];

export const ConfirmDeleteAccountDialog: React.FC<ConfirmDeleteAccountDialogProps> = ({ open, handleCancel }) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const confirmDeletion = useTranslation('SH.BTN.CONFIRMDELETION');

  const handleConfirmDelete = useCallback(async () => {
    await dispatch(deleteCurrentUserAccountThunk({}));
  }, [dispatch]);

  if (!userProfile) {
    return null;
  }

  const { firstName, lastName } = userProfile;

  return (
    <>
      <ConfirmationDialog
        open={open}
        color="error"
        titleId="SH.PG.ACCOUNTDELETIONCONFIRMATION"
        cancelId="SH.BTN.CANCEL"
        confirmId="SH.BTN.CONFIRMDELETION"
        onClose={handleCancel}
        onConfirm={handleConfirmDelete}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: '16px',
          }}
        >
          <I18nFragment id="SH.COMMON.GREETING" args={[`${firstName} ${lastName}`]} />
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: '16px',
          }}
        >
          <I18nFragment id="SH.ACCNTDLTCNFRM.REVIEWCONSEQUENCES" />
        </Typography>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {CONSEQUENCE_IDS.map((id, index) => (
            <ListItem key={`consequence-${index}`}>
              <ListItemIcon>
                <HighlightOffIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: '14px',
                  },
                }}
                primary={<I18nFragment id={id} />}
              />
            </ListItem>
          ))}
        </List>
        <Typography
          variant="body2"
          sx={{
            marginBottom: '16px',
          }}
        >
          <I18nFragment id="SH.ACCNTDLTCNFRM.AGREECONSEQUENCES" args={[confirmDeletion]} />
        </Typography>
      </ConfirmationDialog>
    </>
  );
};
