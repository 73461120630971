import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { I18nFragment } from '../../common/I18nFragment';
import { setError, setShowError } from '../../../features/ui/uiSlice';
import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { AUTH_USER_ROUTE_EXCLUDES } from '../../../helpers/isAuthUserRouteExclude';
import { isValueContainArrayElementPart } from '../../../helpers/isValueContainArrayElementPart';
import { selectTelegramChannel } from '../../../features/user/selectTelegramChannel';
import { TelegramLink } from './TelegramLink';
import { getLocalizedPathname } from '../../../helpers/getLocalizedPathname';
import { selectLocale } from '../../../features/i18n/selectLocale';

const LOGIN_INCLUDES = ['/reset_password/', '/token_invalid/', '/reset_password_token_invalid/'];
LOGIN_INCLUDES.push(...AUTH_USER_ROUTE_EXCLUDES);

const LOGIN_ICON_EXCLUDES = ['/delete_account_complete'];

const LOGIN_PATH_NAMES = [...getLocalizedPathname('/'), ...getLocalizedPathname('/register'), '/forgot_password'];

const HOME_PATH_NAMES = [...getLocalizedPathname('/login')];

export const AnonymousInfo: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const telegramChannel = useAppSelector(selectTelegramChannel);
  const locale = useAppSelector(selectLocale);

  const { pathname } = location;
  const origin = window.location.origin;

  useEffect(() => {
    dispatch(setShowError(false));
    dispatch(setError(null));
  }, [dispatch, pathname]);

  const icons: React.JSX.Element[] = [];
  if (telegramChannel) {
    icons.push(<TelegramLink key="telegram-icon" url={telegramChannel} />);
  }
  if (
    LOGIN_PATH_NAMES.indexOf(pathname) > -1 ||
    (isValueContainArrayElementPart(LOGIN_INCLUDES, pathname) &&
      !isValueContainArrayElementPart(LOGIN_ICON_EXCLUDES, pathname))
  ) {
    icons.push(
      <Tooltip title={<I18nFragment id={'SH.PG.LOGIN'} />} key="login-icon">
        <IconButton
          size="large"
          aria-label="login"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          component={Link}
          to={`${origin}/login/${locale}`}
        >
          <LoginIcon />
        </IconButton>
      </Tooltip>,
    );
  }
  if (HOME_PATH_NAMES.indexOf(pathname) > -1) {
    icons.push(
      <Tooltip title={<I18nFragment id={'SH.BTN.STARTPAGE'} />} key="home-icon">
        <IconButton
          size="large"
          aria-label="go home"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          component={Link}
          to={`${origin}/${locale}`}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>,
    );
  }

  return <>{icons}</>;
};
