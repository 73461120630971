import React, { useCallback } from 'react';
import { I18nFragment } from '../../common/I18nFragment';
import Link from '@mui/material/Link';
import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { setShowTermsOfUse } from '../../../features/ui/uiSlice';
import { selectAppConfig } from '../../../features/config/selectAppConfig';

export const TermsOfUseLink: React.FC = () => {
  const dispatch = useAppDispatch();
  const { termsOfUseEnabled } = useAppSelector(selectAppConfig);

  const showTermsOfUse = useCallback(() => {
    dispatch(setShowTermsOfUse(true));
  }, [dispatch]);

  if (!termsOfUseEnabled) {
    return null;
  }

  return (
    <Link variant="body2" component="button" onClick={showTermsOfUse}>
      <I18nFragment id="SH.PG.TERMSOFUSE" />
    </Link>
  );
};
