import { ComponentType } from 'react';
import { DisplayRuleType } from '../../App/apiWrapper';
import { useAppSelector } from '../../App/hooks';
import { FieldViolation, I18nTypes, ViolationLevel } from '../../App/types';
import { getDisplayRuleElementId } from '../../helpers/displayRules/getDisplayRuleElementId';
import { Translations } from '../i18n';
import { i18n } from '../i18n/i18n';
import { selectTranslationEntries } from '../i18n/selectTranslationEntries';
import { selectViolations } from '../violations/selectViolations';
import { PageFragmentProps } from '../withPageFragmentInit';
import { selectDisplayRuleType } from './selectDisplayRuleType';
import { selectFieldMaxLength } from './selectFieldMaxLength';
import { DataType } from './index';

interface ViolationProps {
  hasViolation?: boolean;
  violationMessages?: string[];
  violationLevel?: ViolationLevel;
}

export interface WithDisplayRuleProps extends ViolationProps, PageFragmentProps {
  id: I18nTypes;
  displayRuleIndexes?: number[];
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;

  getData?(): DataType;
}

const getViolationProps = (violations?: FieldViolation[], entries?: Translations): ViolationProps => {
  return violations && violations.length > 0
    ? {
        hasViolation: true,
        violationMessages: violations.map((value) => i18n(value.ruleId, entries, ...value.args)),
        violationLevel: violations[0].violationLevel,
      }
    : {};
};

export const withDisplayRule = <T extends WithDisplayRuleProps>(Component: ComponentType<T>) => {
  return (props: T) => {
    const { id: displayRuleId, displayRuleIndexes, screenRef, actionRef } = props;
    const id = getDisplayRuleElementId(displayRuleId, displayRuleIndexes);
    const violations = useAppSelector(selectViolations(id));
    const translationEntries = useAppSelector(selectTranslationEntries);
    const violationProps = getViolationProps(violations, translationEntries);
    const displayRuleType = useAppSelector(selectDisplayRuleType(screenRef, actionRef, id));
    const maxLength = useAppSelector(selectFieldMaxLength(screenRef, actionRef, id));
    if (displayRuleType === null || displayRuleType === DisplayRuleType.HIDDEN) {
      return null;
    }
    const disabled = displayRuleType === DisplayRuleType.READ_ONLY ? { disabled: true } : {};
    const required = displayRuleType === DisplayRuleType.MANDATORY ? { required: true } : {};
    return (
      <Component {...props} {...disabled} {...required} {...(maxLength ? { maxLength } : {})} {...violationProps} />
    );
  };
};
