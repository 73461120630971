import React from 'react';
import { ExternalLinkIcon, ExternalLinkIconProps } from '../../common/ExternalLinkIcon';
import { TelegramIcon } from '../../icons/TelegramIcon';

interface TelegramLinkProps {
  url: ExternalLinkIconProps['url'];
}

export const TelegramLink: React.FC<TelegramLinkProps> = ({ url }) => (
  <ExternalLinkIcon
    size="large"
    aria-label="telegram"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    color="inherit"
    url={url}
    children={<TelegramIcon />}
  />
);
