import { ServerTimeZone } from '../App/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const configureTimeZone = (timeZone: ServerTimeZone | null): void => {
  if (!timeZone) {
    return;
  }
  if (timeZone === ServerTimeZone.UTC) {
    dayjs.extend(utc);
  } else {
    throw new Error('time zone not supported');
  }
};
