import React, { ComponentType, useEffect, useMemo } from 'react';
import GoogleAnalyticsService from '../../services/GoogleAnalyticsService';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../App/hooks';
import { selectGoogleAnalytics } from '../../features/config/selectGoogleAnalytics';
import { useCookieConsent } from '../../hooks/useCookieConsent';

export const withGoogleAnalytics =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
  (props) => {
    const location = useLocation();
    const googleAnalytics = useAppSelector(selectGoogleAnalytics);
    const { valid, gaAccepted } = useCookieConsent();

    const gaEnabled = useMemo(
      () => googleAnalytics.enabled && valid && gaAccepted,
      [googleAnalytics, valid, gaAccepted],
    );

    useEffect(() => {
      if (gaEnabled) {
        GoogleAnalyticsService.initGA(googleAnalytics);
        GoogleAnalyticsService.logPageView();
      } else {
        GoogleAnalyticsService.reset();
      }
    }, [gaEnabled, googleAnalytics]);

    useEffect(() => {
      if (gaEnabled) {
        GoogleAnalyticsService.logPageView();
      }
    }, [gaEnabled, location]);

    return <WrappedComponent {...props} />;
  };
