import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

export type ExternalLinkIconProps = IconButtonProps & {
  url: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  children: ReactNode;
};

export const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = (props) => {
  const { url, children, ...rest } = props;
  return (
    <a href={url} target="_blank">
      <IconButton {...rest}>{children}</IconButton>
    </a>
  );
};
