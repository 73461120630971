import React, { ComponentType } from 'react';
import { useAppSelector } from './hooks';
import { selectAppConfig } from '../features/config/selectAppConfig';
import { LocaleProvider } from '../hooks/LocaleProvider';
import { AppCookieConsent } from '../components/layout/AppCookieConsent/AppCookieConsent';
import { CookieConsentProvider } from '../hooks/CookieConsentProvider';
import { useNavigator } from '../hooks/useNavigator';
import { isBot } from '../helpers/seo/isBot';
import { HelmetProvider } from 'react-helmet-async';
import { SeoMetaInfo } from '../components/seo/SeoMetaInfo';

export const withAppProviders =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
  (props) => {
    const {
      localeCookieName,
      localeCookieExpiresInDays,
      consentCookieName,
      consentCookieVersion,
      consentCookieExpiresInDays,
    } = useAppSelector(selectAppConfig);
    const { userAgent } = useNavigator();

    if (!localeCookieName || !localeCookieExpiresInDays || !consentCookieName || !consentCookieVersion) {
      return null;
    }

    const component = (
      <HelmetProvider>
        <SeoMetaInfo />
        <WrappedComponent {...props} />
      </HelmetProvider>
    );

    if (localeCookieName && isBot(userAgent)) {
      return (
        <LocaleProvider localeCookieName={localeCookieName} localeCookieExpiresInDays={localeCookieExpiresInDays}>
          {component}
        </LocaleProvider>
      );
    }

    return (
      <LocaleProvider localeCookieName={localeCookieName} localeCookieExpiresInDays={localeCookieExpiresInDays}>
        <CookieConsentProvider
          consentCookieName={consentCookieName}
          consentCookieVersion={consentCookieVersion}
          consentCookieExpiresInDays={consentCookieExpiresInDays}
        >
          <AppCookieConsent />
          {component}
        </CookieConsentProvider>
      </LocaleProvider>
    );
  };
