import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../App/hooks';
import { selectAppConfig } from '../../../features/config/selectAppConfig';
import { withDisplayRule } from '../../../features/displayRules/withDisplayRule';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { selectDateFormat } from '../../../features/user/selectDateFormat';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import localizationService from '../../../services/LocalizationService';
import { I18nFragment } from '../I18nFragment';
import { FormDatePickerProps } from './FormDatePickerProps';
import { createPopperModifiers } from './createPopperModifiers';
import { configureTimeZone } from '../../../helpers/configureTimeZone';

type FormDatePickerPropsOnChange = FormDatePickerProps['onChange'];

export const FormDatePickerComponent: React.FC<FormDatePickerProps> = (props) => {
  const {
    id,
    value,
    fullWidth,
    handleValueChange,
    displayRuleIndexes,
    hasViolation,
    violationMessages,
    violationLevel,
    actionRef,
    screenRef,
    getData,
    disabled,
    required,
    small,
    clearable = true,
    variant = 'outlined',
    showLabel = true,
    ...rest
  } = props;
  const [cleared, setCleared] = useState<boolean>(false);
  const dateFormat = useAppSelector(selectDateFormat);
  const { dateFormat: serverFormat, timeZone } = useAppSelector(selectAppConfig);
  const [stateValue, setStateValue] = useState<FormDatePickerProps['value']>(value);
  const locale = useAppSelector(selectLocale);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  configureTimeZone(timeZone);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const onChange: FormDatePickerPropsOnChange = useCallback(
    (newValue, { validationError }) => {
      setStateValue(newValue ? newValue.format(dateFormat) : null);
      handleValueChange(
        newValue ? dayjs.utc(newValue.format(dateFormat)!, dateFormat).format(serverFormat!) : null,
        !validationError,
      );
    },
    [handleValueChange, dateFormat, serverFormat],
  );

  const helperTextItems = getViolationItems(violationMessages);

  const dateValue = useMemo(() => {
    if (stateValue && dateFormat) {
      return dayjs(stateValue, [dateFormat]);
    }
    return null;
  }, [stateValue, dateFormat]);

  const localization = useMemo(() => localizationService.getDatePickerLocalization(locale), [locale]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={localization.adapterLocale}
      localeText={localization.localeText}
    >
      <DatePicker
        {...rest}
        {...(showLabel ? { label: <I18nFragment id={id} /> } : {})}
        format={dateFormat}
        value={dateValue}
        closeOnSelect
        onChange={onChange}
        slotProps={{
          textField: {
            fullWidth: fullWidth === true,
            variant: variant,
            error: hasViolation,
            disabled,
            required,
            ...(small ? { sx: { height: '31px' } } : {}),
            helperText: <>{helperTextItems}</>,
          },
          ...(clearable ? { field: { clearable: true, onClear: () => setCleared(true) } } : {}),
          popper: {
            modifiers: createPopperModifiers(props),
          },
          inputAdornment: {},
        }}
      />
    </LocalizationProvider>
  );
};

export const FormDatePicker = withDisplayRule(FormDatePickerComponent);
