import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, SxProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import Typography from '@mui/material/Typography';
import React, { FC, useMemo } from 'react';
import { ReferenceData } from '../../../App/apiWrapper';
import { I18nTypes, RefIdType } from '../../../App/types';
import { WithDisplayRuleProps } from '../../../features/displayRules/withDisplayRule';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import { I18nFragment } from '../I18nFragment';

export type FormSelectProps = SelectProps<RefIdType> &
  Partial<SelectInputProps<RefIdType>> &
  WithDisplayRuleProps & {
    id: I18nTypes;
    placeholder?: I18nTypes;
    showLabel?: boolean;
    emptyOptionId?: I18nTypes;
    options: Array<ReferenceData>;
    selectSx?: SxProps<Theme>;
  };

export const FormSelect: FC<FormSelectProps> = ({
  id,
  placeholder,
  hasViolation,
  violationMessages,
  fullWidth,
  variant,
  onChange,
  value,
  options,
  disabled,
  required,
  emptyOptionId,
  selectSx,
  showLabel = true,
  sx = {},
}) => {
  const labelText = useTranslation(id);
  const placeholderText = useTranslation(placeholder);
  const helperTextItems = useMemo(() => getViolationItems(violationMessages), [violationMessages]);

  return (
    <FormControl
      fullWidth={fullWidth}
      {...(hasViolation ? { error: true } : {})}
      disabled={disabled}
      required={required}
      sx={{ mt: '16px', mb: '8px', ...sx }}
    >
      {showLabel && <InputLabel id={`select_label_${id}`}>{labelText}</InputLabel>}
      <Select
        displayEmpty={!showLabel}
        labelId={`select_label_${id}`}
        id={`select_${id}`}
        value={value || ''}
        {...(showLabel ? { label: labelText } : {})}
        variant={variant}
        onChange={onChange}
        {...(selectSx ? { sx: selectSx } : {})}
        renderValue={(selected) => {
          if (selected.length === 0 && placeholderText) {
            return (
              <Typography component="div" variant={'body1'} sx={{ color: grey[400] }}>
                {placeholderText}
              </Typography>
            );
          }
          const option = options.find((option) => option.id === selected);
          return option ? option.name : '';
        }}
      >
        {emptyOptionId && (
          <MenuItem id={`select_menu_item_empty`} key={'select_menu_item_empty'} value={''}>
            <em>
              <I18nFragment id={emptyOptionId} />
            </em>
          </MenuItem>
        )}
        {options.map(({ id, name }) => (
          <MenuItem id={`select_menu_item_${id}`} key={`select_menu_item_${id}`} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperTextItems}</FormHelperText>
    </FormControl>
  );
};
