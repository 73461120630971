import {
  combineReducers,
  configureStore,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import config from '../features/config/configSlice';
import displayRules from '../features/displayRules/displayRulesSlice';
import horoscope from '../features/horoscope/horoscopeSlice';
import i18n, { resetTermsOfUse } from '../features/i18n/i18nSlice';
import label from '../features/label/labelSlice';
import people from '../features/people/peopleSlice';
import person from '../features/person/personSlice';
import refdata from '../features/refdata/refdataSlice';
import ui, {
  setError,
  setShowError,
  setShowTermsOfUse,
  setTermsOfUseStatus,
  startLoader,
  stopLoader,
} from '../features/ui/uiSlice';
import changePassword from '../features/user/changePassword/changePasswordSlice';
import forgotPassword from '../features/user/forgotPassword/forgotPasswordSlice';
import resetPassword from '../features/user/resetPassword/resetPasswordSlice';
import deleteProfile from '../features/user/deleteProfile/deleteProfileSlice';
import login from '../features/user/login/loginSlice';
import register from '../features/user/register/registerSlice';
import user from '../features/user/userSlice';
import violations from '../features/violations/violationsSlice';
import { Errors, TermsOfUseStatus } from './types';

let numberOfCalls = 0;
let timeout;

const showAppSpinner = (store) => {
  const dispatch = store.dispatch;
  if (numberOfCalls === 0) {
    dispatch(startLoader());
  }
  clearTimeout(timeout);
  numberOfCalls++;
};

const hideAppSpinner = (store) => {
  const dispatch = store.dispatch;
  numberOfCalls--;
  if (numberOfCalls === 0) {
    // Adding timeout to reduce loader jumping between calls.
    timeout = setTimeout(() => dispatch(stopLoader()), 500);
  }
};

const appSpinnerMiddleware = (store) => (next) => (action) => {
  if (isPending(action)) {
    showAppSpinner(store);
  }
  if (isFulfilled(action) || isRejected(action) || isRejectedWithValue(action)) {
    hideAppSpinner(store);
  }
  return next(action);
};

const handleError = (store, error: Error) => {
  const dispatch = store.dispatch;
  switch (error.name) {
    case Errors.TERMS_OF_USE_NOT_ACCEPTED_ERROR:
      dispatch(setShowTermsOfUse(true));
      dispatch(setTermsOfUseStatus(TermsOfUseStatus.NOT_ACCEPTED));
      dispatch(resetTermsOfUse());
      break;
    case Errors.TERMS_OF_USE_UPDATED_ERROR:
      dispatch(setShowTermsOfUse(true));
      dispatch(setTermsOfUseStatus(TermsOfUseStatus.OLD_VERSION_ACCEPTED));
      dispatch(resetTermsOfUse());
      break;
    case Errors.TOO_MANY_REQUESTS_ERROR:
      dispatch(setShowError(true));
      dispatch(setError({ id: 'SH.SVC.TOOMANYREQUESTS' }));
      break;
    case Errors.HTTP_ERROR:
      break;
    default:
      console.log(`Unexpected error handled: name=[${error.name}], message=[${error.message}]`);
      break;
  }
};

const errorMiddleware = (store) => (next) => (action) => {
  if (isRejected(action)) {
    const { error } = action;
    handleError(store, error);
  }
  return next(action);
};

const rootReducer = combineReducers({
  horoscope,
  i18n,
  user,
  refdata,
  displayRules,
  violations,
  ui,
  login,
  register,
  forgotPassword,
  resetPassword,
  config,
  changePassword,
  deleteProfile,
  person,
  people,
  label,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appSpinnerMiddleware, errorMiddleware),
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
