import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { I18nTypes } from '../../App/types';
import { I18nFragment } from './I18nFragment';
import { ButtonOwnProps } from '@mui/material/Button/Button';
import { useAppSelector } from '../../App/hooks';
import { selectViolations } from '../../features/violations/selectViolations';
import { FormMarkdown } from './FormMarkdown';

interface ViolationDialogProps {
  titleId: I18nTypes;
  elementId: I18nTypes;
  closeId?: I18nTypes;
  open: boolean;
  color?: ButtonOwnProps['color'];

  onClose(): void;
}

export const ViolationDialog: React.FC<ViolationDialogProps> = (props) => {
  const { titleId, elementId, closeId = 'SH.BTN.CLOSE', open, color = 'primary', onClose } = props;

  const violations = useAppSelector(selectViolations(elementId));

  const children = violations?.map((violation) => {
    const { ruleId, args } = violation;
    return <FormMarkdown key={`violation-${ruleId}`} id={ruleId} args={args} />;
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog">
      <DialogTitle
        sx={{
          fontWeight: 'bold',
        }}
        id="confirm-dialog"
      >
        <I18nFragment id={titleId} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color={color}>
          <I18nFragment id={closeId} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
