import React, { useCallback } from 'react';
import { I18nFragment } from '../../common/I18nFragment';
import Link from '@mui/material/Link';
import { useAppDispatch } from '../../../App/hooks';
import { setShowAboutUs } from '../../../features/ui/uiSlice';

export const AboutUsLink: React.FC = () => {
  const dispatch = useAppDispatch();

  const showAboutUs = useCallback(() => {
    dispatch(setShowAboutUs(true));
  }, [dispatch]);

  return (
    <Link variant="body2" component="button" onClick={showAboutUs}>
      <I18nFragment id="SH.PG.ABOUTUS" />
    </Link>
  );
};
